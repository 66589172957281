import { generateUID } from "./fingerprint";

export const welcomeBox = () => {
    const unique = generateUID();
    const hostname = location.hostname;
    const siteName = hostname.replace("www.", "");
    const template = document.createRange().createContextualFragment(`
    <style>
    .${unique}-sticky {

    }
    .${unique}-wrapper {
        background-color: rgba(0,0,0,0.33);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
    .${unique} {
        text-align: center;
        font-family: "Helvetica Neue",Arial,sans-serif;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        background-color: white;
        padding 1.5rem 0 1rem 0;
        z-index: 99999999999999999;
        background-image: url("data:image/svg+xml, %3Csvg fill='rgba(0,0,0,0.025)' width='800px' height='800px' viewBox='0 0 32 32' id='icon' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none%3B%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Echip%3C/title%3E%3Cpath d='M11 11V21H21V11Zm8 8H13V13h6Z' transform='translate(0)'/%3E%3Cpath d='M30 13V11H26V8a2 2 0 0 0-2-2H21V2H19V6H13V2H11V6H8A2 2 0 0 0 6 8v3H2v2H6v6H2v2H6v3a2 2 0 0 0 2 2h3v4h2V26h6v4h2V26h3a2 2 0 0 0 2-2V21h4V19H26V13ZM24 24H8V8H24Z' transform='translate(0)'/%3E%3Crect id='_Transparent_Rectangle_' data-name='%26lt%3BTransparent Rectangle%26gt%3B' class='cls-1' width='32' height='32'/%3E%3C/svg%3E");
        background-size: 4rem;
        background-position: right bottom;
    }

    .${unique} button {
        margin: 0 auto;
        width: 90%;
        padding: 0.70rem 0;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        background-color: #40b400;
        color: white;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        font: inherit;
    }

    .${unique} button.${unique}-btn-eng {
        background-color: #1e81b0;
    }

    .${unique} h3 {
        font-family: "Helvetica Neue",Arial,sans-serif;
        margin: 0.5rem auto;
        color: black;
        font-weight: 500;
        font-size: 1rem;
        text-align: left;
        width: 90%;
    }

    .${unique}-titles {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;
    }

    .${unique}-title {
        flex-grow: 1;
        flex-basis: 50%;
    }

    .${unique}__text-xs, .${unique}__text-xs a  {
        text-align: center;
        font-size: 9px;
        color: rgba(0,0,0, 0.85);
    }

    .${unique}__text-xs {
        width: 90%;
        margin: auto;
        padding: 1rem 0;
    }

    .${unique}__text-xs a {
        text-decoration: underline solid rgba(0,0,0, 0.4);
    }
    </style>
    <div id="${unique}" class="${unique}-wrapper">
        <div class="${unique}">
            <h3>Ymweld â ${siteName} yn Cymraeg? Continue to ${siteName} in English?</h3>
            <div class="${unique}-titles">
            <div class="${unique}-title">
            <button onClick="__cwApi.hideUI('CYM');">Cymraeg</button>
            <p class="${unique}__text-xs">Bydd <a href="https://choose.wales/" target="_blank">Aber Technologies</a> yn cofio'ch dewis ar wefannau ac apiau sy'n cymryd rhan.</p>
            </div>
            <div class="${unique}-title">
            <button class="${unique}-btn-eng"onClick="__cwApi.hideUI('ENG');">English</button> 
            <p class="${unique}__text-xs"><a href="https://choose.wales/" target="_blank">Aber Technologies</a> will remember your preference on participating websites and apps.</p>
            </div> 
            </div>
            </div>
        </div>
    </div>
`);

    document.body.appendChild(template);
    return unique;
}
import getBrowserFingerprint from './fingerprint';
import jsCookie from 'js-cookie';
import { welcomeBox } from './ui';

const API_BASE = "https://cw.choose.wales/api";

const validLang = (lang) => lang === 'ENG' || lang === 'CYM';

class cwApi {
    constructor() {
        this.fingerprint = getBrowserFingerprint();
        this.welcomeId = '';
        this.preference = '';
        this.redirects = {};
        this.showUI = this.init; // legacy api...already
    }
    setPreference = async (language) => {
        if (!validLang(language)) return console.warn('CW.js - Could not set invalid language preference');
        this.preference = language;
        jsCookie.set('__cwApi', language, { expires: 365, sameSite: 'Strict' });
        await fetch(`${API_BASE}/preference`, {
            method: 'POST', body:
                JSON.stringify({
                    language,
                    fingerprint: this.fingerprint
                })
        });
    }
    /**
     * @description Attempts to read a preference from cookies or remote fingerprint storage
     * @returns 
     */
    getPreference = async () => {
        try {
            const localPreference = jsCookie.get('__cwApi');
            if (localPreference && validLang(localPreference)) {
                this.preference = localPreference;
                return localPreference;
            }
            const response = (await fetch(`${API_BASE}/check?f=${this.fingerprint}`));
            const remotePref = await response.json();
            if (remotePref?.l && validLang(remotePref?.l)) {
                jsCookie.set('__cwApi', remotePref?.l, { expires: 365, sameSite: 'Strict' });
                this.preference = remotePref?.l;
                return remotePref?.l;
            }
        } catch {
            return false;
        }
    }

    forceUI = () => {
        this.welcomeId = welcomeBox();
    }
    setCallback = (callbackFunction) => {
        this.callback = callbackFunction;
    }
    setRedirect(lang, url) {
        if (validLang(lang)) this.redirects[lang] = url;
    }
    runRedirect(lang) {
        const redirect = this.redirects[lang];
        if (redirect && (window.location.href !== redirect)) window.location.replace(redirect);
    }
    hideUI = (pref) => {
        this.setPreference(pref);
        if (this.callback) this.callback();
        if (this.redirects[pref]) window.location.replace(this.redirects[pref]);
        document.querySelector(`#${this.welcomeId}`).remove();
    }

    init = async () => {
        const existingPref = await this.getPreference();
        if (!existingPref) {
            this.welcomeId = welcomeBox();
        } else {
            const pref = this.preference;
            if (this.callback) this.callback();
            this.runRedirect(pref);
        }
    }
}

if (window) window.__cwApi = new cwApi();